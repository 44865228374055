import './Home.scss';
import Hero from './Hero/Hero';
import Why from './Why/Why';
import Start from './Start/Start';
import Clients from './Clients/Clients';
import Banner from './Banner/Banner';

function Home({ lang }) {
  return (
    <div>
      <Hero lang={lang} />
      <Why lang={lang} />
      <Start lang={lang} />
      <Clients lang={lang} />
      <Banner lang={lang} />
    </div>
  );
}

export default Home;

import './Services.scss';
import OurServices from './OurServices/OurServices';
import Banner from '../Home/Banner/Banner';
import AdditionalServices from './AdditionalServices/AdditionalServices';

function Services({ lang }) {
  return (
    <div className="Services">
      <OurServices lang={lang} />
      <Banner lang={lang} />
      <AdditionalServices lang={lang} />
    </div>
  );
}

export default Services;

import './Footer.scss';
import logo from './img/YXY__logo.png';
import facebook from './img/facebook.svg';
import instagram from './img/instagram.svg';
import linkedin from './img/linkedin.svg';
import Nav from '../Nav';
import { Link } from 'react-router-dom';

function Footer({ lang }) {
  return (
    <footer className="Footer">
      <div className="Footer__top">
        <a href="/" className="Footer__top__logo">
          <img src={logo} alt="YXY logo" />
        </a>
        <Nav lang={lang} className="Footer__top__menu" />
        <div className="Footer__top__info">
          <a href="mailto:info@yxyimmigration.ca">info@yxyimmigration.ca</a>
          <a href="tel:+18673367770">+1 867-336-7770</a>
          <p>202 Strickland St, Whitehorse, YT Y1A 2J8, Canada</p>
          <div className="Footer__top__info__socials">
            <a
              href="https://www.linkedin.com/in/julie-croquison-ab98364a/?originalSubdomain=ca"
              target="blank"
            >
              <img src={linkedin} alt="linkedin logo" />
            </a>
            <a href="https://www.facebook.com/YXYimmigration" target="blank">
              <img src={facebook} alt="facebook logo" />
            </a>
            <a
              href="https://www.instagram.com/yxyimmigrationconsultant/"
              target="blank"
            >
              <img
                src={instagram}
                alt="instagram logo"
                className="instagramIcon"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="Footer__down">
        <div className="Footer__down__copyright">
          <span>{lang == 'en' ? 'Copyright' : `Droits D'auteur`} &copy;</span>
          <span>2023 YXY Immigration Consultant Inc.</span>
        </div>
        <div className="Footer__down__termsPolicy">
          <Link to="/terms">
            {lang == 'en' ? 'Terms & Condition' : 'Termes et Conditions'}
          </Link>
          <Link to="/policy">
            {lang == 'en' ? 'Privacy Policy' : 'Politique de Confidentialité'}
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import { useContext, useEffect, useState } from 'react';
import Context from '../../../Context';
import './Start.scss';
import photo from './img/start.jpg';
import line1920 from './img/1920.svg';
import line1440 from './img/1440.svg';
import line1000 from './img/1000.svg';
import line768 from './img/768.svg';
import line480 from './img/480.svg';
import line320 from './img/320.svg';

function Start({ lang }) {
  const { jsonResult } = useContext(Context);

  // HowToStart Data
  const [howToStartData, setHowToStartData] = useState(null);

  useEffect(() => {
    if (jsonResult && jsonResult.how_to_start) {
      setHowToStartData(jsonResult.how_to_start);
    }
  }, [jsonResult]);

  return (
    <section className="Start">
      <img src={photo} alt="Start" />
      <div className="Start__text">
        <h2>{lang == 'en' ? 'How to start?' : 'Comment commencer?'}</h2>
        {howToStartData && (
          <>
            <div className="Start__text__step1">
              <h6>{lang == 'en' ? 'Step 1:' : 'Étape 1:'}</h6>
              <h4>
                {lang == 'en'
                  ? howToStartData.step1_title_en
                  : howToStartData.step1_title_fr}
              </h4>
              {lang == 'en' ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: howToStartData.step1_description_en,
                  }}
                />
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: howToStartData.step1_description_fr,
                  }}
                />
              )}
            </div>
            <div className="Start__text__line">
              <div className="Start__text__line__wrapper">
                <picture>
                  <source
                    media="(max-width: 450px)"
                    srcSet={line320}
                    className="line-image"
                  />
                  <source
                    media="(max-width: 767px)"
                    srcSet={line480}
                    className="line-image"
                  />
                  <source
                    media="(max-width: 1000px)"
                    srcSet={line768}
                    className="line-image"
                  />
                  <source
                    media="(max-width: 1439px)"
                    srcSet={line1000}
                    className="line-image"
                  />
                  <source
                    media="(max-width: 1919px)"
                    srcSet={line1440}
                    className="line-image"
                  />
                  <img src={line1920} className="line-image" />
                </picture>
              </div>
            </div>
            <div className="Start__text__step2">
              <h6>{lang == 'en' ? 'Step 2:' : 'Étape 2:'}</h6>
              <h4>
                {lang == 'en'
                  ? howToStartData.step2_title_en
                  : howToStartData.step2_title_fr}
              </h4>
              {lang == 'en' ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: howToStartData.step2_description_en,
                  }}
                />
              ) : (
                <p
                  style={{ whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{
                    __html: howToStartData.step2_description_fr,
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default Start;

function ServicesCard({ title, text, image, className }) {
  return (
    <div className={`ServicesCard ${className}`}>
      <img src={image} alt="service photo" />
      <div className="ServicesCard__text">
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default ServicesCard;

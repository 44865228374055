import React, { useContext, useEffect, useState } from 'react';
import Context from '../Context';
import './TermsPolicy.scss';

function TermsPolicy({ lang, type }) {
  const { jsonResult } = useContext(Context);
  const [termsData, setTermsData] = useState(null);
  const [policyData, setPolicyData] = useState(null);
  console.log(jsonResult);

  useEffect(() => {
    if (jsonResult && jsonResult.terms) {
      setTermsData(jsonResult.terms);
    }
    if (jsonResult && jsonResult.policy) {
      setPolicyData(jsonResult.policy);
    }
  }, [jsonResult]);

  return (
    <div className="TermsPolicy">
      {type == 'terms'
        ? termsData &&
          (lang == 'en' ? (
            <p
              dangerouslySetInnerHTML={{
                __html: termsData.terms_en,
              }}
            />
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: termsData.terms_fr,
              }}
            />
          ))
        : policyData &&
          (lang == 'en' ? (
            <p
              dangerouslySetInnerHTML={{
                __html: policyData.policy_en,
              }}
            />
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: policyData.policy_fr,
              }}
            />
          ))}
    </div>
  );
}

export default TermsPolicy;

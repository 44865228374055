import './OurServices.scss';
import ServicesCard from './ServicesCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import Context from '../../../Context';
import 'swiper/css';
import 'swiper/css/navigation';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

function OurServices({ lang }) {
  const { homeUrl, jsonResult } = useContext(Context);

  const [ourServicesData, setOurServicesData] = useState(null);

  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleListItemClick = (index) => {
    setActiveIndex(index);
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  useEffect(() => {
    if (jsonResult && jsonResult.our_services) {
      setOurServicesData(jsonResult.our_services);
    }
  }, [jsonResult]);

  return (
    <section className="OurServices">
      <div className="OurServices__list_desktop ">
        <h2>{lang == 'en' ? 'Our services' : 'Nos services'}</h2>
        <ul className="OurServices__list_desktop__items">
          {ourServicesData &&
            (lang === 'en'
              ? ourServicesData.map((service, index) => (
                  <li
                    key={index}
                    className={activeIndex === index ? 'active' : ''}
                    onClick={() => handleListItemClick(index)}
                  >
                    {service.title_en}
                  </li>
                ))
              : ourServicesData.map((service, index) => (
                  <li
                    key={index}
                    className={activeIndex === index ? 'active' : ''}
                    onClick={() => handleListItemClick(index)}
                  >
                    {service.title_fr}
                  </li>
                )))}
        </ul>
      </div>

      <Swiper
        className="OurServices__carousel_desktop"
        spaceBetween={20}
        ref={swiperRef}
        allowTouchMove={false}
        speed={1000}
        breakpoints={{
          768: {
            slidesPerView: 1.5,
          },
          800: {
            slidesPerView: 1.8,
          },
          1000: {
            slidesPerView: 2,
          },
          1250: {
            slidesPerView: 2.5,
          },
          1700: {
            slidesPerView: 3,
          },
        }}
      >
        {ourServicesData &&
          (lang === 'en'
            ? ourServicesData.map((service, index) => (
                <SwiperSlide key={index}>
                  <ServicesCard
                    title={service.title_en}
                    text={service.text_en}
                    image={homeUrl + 'uploads/' + service.image}
                    className={index === activeIndex ? 'active' : ''}
                  />
                </SwiperSlide>
              ))
            : ourServicesData.map((service, index) => (
                <SwiperSlide key={index}>
                  <ServicesCard
                    title={service.title_fr}
                    text={service.text_fr}
                    image={homeUrl + 'uploads/' + service.image}
                    className={index === activeIndex ? 'active' : ''}
                  />
                </SwiperSlide>
              )))}
      </Swiper>

      <div className="OurServices__mobile ">
        <h2>{lang == 'en' ? 'Our services' : 'Nos services'}</h2>
        <Accordion allowZeroExpanded>
          {ourServicesData &&
            (lang === 'en'
              ? ourServicesData.map((service, index) => (
                  <AccordionItem
                    onClick={() => setActiveIndex(index)}
                    key={index}
                    activeClassName={
                      activeIndex === index
                        ? 'accordion__item active'
                        : 'accordion__item'
                    }
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {service.title_en}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <img src={homeUrl + 'uploads/' + service.image} />
                    </AccordionItemPanel>
                    <AccordionItemPanel>
                      <p>{service.text_en}</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))
              : ourServicesData.map((service, index) => (
                  <AccordionItem
                    onClick={() => setActiveIndex(index)}
                    key={index}
                    activeClassName={
                      activeIndex === index
                        ? 'accordion__item active'
                        : 'accordion__item'
                    }
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {service.title_fr}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <img src={homeUrl + 'uploads/' + service.image} />
                    </AccordionItemPanel>
                    <AccordionItemPanel>
                      <p>{service.text_fr}</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                )))}
        </Accordion>
      </div>
    </section>
  );
}

export default OurServices;

import { NavLink } from 'react-router-dom';

function Nav({ lang, className, closeMenu }) {
  return (
    <nav className={className}>
      <NavLink to="/" onClick={closeMenu}>
        {lang == 'en' ? 'Home Page' : "Page d'acceuil"}
      </NavLink>
      <NavLink to="/services" onClick={closeMenu}>
        {lang == 'en' ? 'Services' : 'Prestations de service'}
      </NavLink>
      <NavLink to="/contact" onClick={closeMenu}>
        {lang == 'en' ? 'Contact Us' : 'Contactez-nous'}
      </NavLink>
    </nav>
  );
}

export default Nav;

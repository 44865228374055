import './Clients.scss';
import { format } from 'date-fns';

function Card({ comment, lang }) {
  const { comment: commentText, author_name, time, rating } = comment;
  const formattedDate = format(new Date(time), 'MMMM d, yyyy');

  return (
    <div className="Card">
      <p>{commentText}</p>
      <h6>{author_name}</h6>
      <span>{formattedDate}</span>
    </div>
  );
}

export default Card;

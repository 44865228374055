import { slide as Menu } from 'react-burger-menu';
import './Header.scss';
import logo from './img/YXY__logo.png';
import Nav from '../Nav';
import LanguageSwitcher from '../LanguageSwitcher';
import { FiMenu, FiX } from 'react-icons/fi';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header({ lang, langSwitcher }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // Блокируем скролл фона при открытом бургере
      document.body.style.overflow = 'hidden';
    } else {
      // Разблокируем скролл фона при закрытом бургере
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="Header">
      <Link to="/" className="Header__logo">
        <img src={logo} alt="YXY logo" />
      </Link>
      <div className="Header__menu">
        <Nav lang={lang} className="Header__menu__list" />
        <a
          className="Header__menu__button"
          href="https://calendly.com/yxyimmigration"
          target="blank">
          {lang == 'en' ? 'Book a Consultation' : 'Réserver une consultation'}
        </a>
        <LanguageSwitcher lang={lang} langSwitcher={langSwitcher} />
        <Menu
          className="Header__menu__burger"
          right
          width={'100%'}
          isOpen={isOpen}
          onOpen={handleMenuToggle}
          onClose={handleMenuToggle}
          customCrossIcon={
            <FiX className="burgerClose" onClick={handleMenuToggle} />
          }
          customBurgerIcon={
            <FiMenu className="burgerIcon" onClick={handleMenuToggle} />
          }
          styles={{
            bmMenuWrap: {
              width: '100%',
              position: 'fixed',
              top: '0',
              height: '100vh',
              zIndex: '1000',
            },
            bmMenu: {
              background: '#ea3324',
              width: '100%',
              height: '100%',
            },
            '#react-burger-menu-btn': {
              width: 'fit-content',
              right: '0',
            },
            bmBurgerButton: {
              width: '24px',
              height: '24px',
              svg: {
                width: '100%',
                height: '100%',
              },
            },
            bmCross: {
              stroke: '#E3ADAD',
            },
            bmCrossButton: {
              height: '24px',
              width: '24px',
              top: '20px',
              right: '20px',
            },
            bmItemList: {
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}>
          <Nav
            lang={lang}
            className="Header__menu__burger__list"
            closeMenu={handleMenuToggle}
          />
          <a
            className="Header__menu__burger__button"
            href="https://calendly.com/yxyimmigration"
            target="blank">
            {lang == 'en' ? 'Book a Consultation' : 'Réserver une Consultation'}
          </a>
        </Menu>
      </div>
    </header>
  );
}

export default Header;

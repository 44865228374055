import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import TermsPolicy from './components/TermsPolicy';
import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import Contact from './pages/Contact/Contact';
import ScrollToTop from './components/scrollToTop';
import './App.css';
import { useEffect, useState } from 'react';
import Context from './Context';

//const homeUrl = 'http://localhost:8888/yxy-admin/';
const homeUrl = 'https://yxyimmigration.ca/';

function App() {
  const [lang, setLang] = useState('en');
  const langSwitcher = () => {
    lang === 'en' ? setLang('fr') : setLang('en');
  };

  // API result
  const [jsonResult, setJsonResult] = useState(null);

  useEffect(() => {
    fetch(`${homeUrl}/api.php`)
      .then((response) => response.json())
      .then((data) => {
        setJsonResult(data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Header lang={lang} langSwitcher={langSwitcher} />
        <Context.Provider value={{ homeUrl, jsonResult }}>
          <Routes>
            <Route exact path="/" element={<Home lang={lang} />} />
            <Route exact path="/services" element={<Services lang={lang} />} />
            <Route exact path="/contact" element={<Contact lang={lang} />} />
            <Route
              exact
              path="/terms"
              element={<TermsPolicy lang={lang} type="terms" />}
            />
            <Route
              exact
              path="/policy"
              element={<TermsPolicy lang={lang} type="policy" />}
            />
          </Routes>
        </Context.Provider>
        <Footer lang={lang} />
      </BrowserRouter>
    </div>
  );
}

export default App;

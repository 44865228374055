import React, { useContext, useState } from 'react';
import axios from 'axios';
import Context from '../../Context';
import './Contact.scss';

function Contact({ lang }) {
  const { homeUrl } = useContext(Context);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
    consent: false,
  });

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormData((prevState) => ({ ...prevState, [id]: fieldValue }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      sendmail: 'true',
      ...formData,
    };

    const params = new URLSearchParams(data).toString();

    axios
      .get(`${homeUrl}/send.php?${params}`)
      .then((response) => {
        console.log(response); // Обработка успешного ответа от сервера
        alert('message sent successfully');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
          consent: false,
        });
      })
      .catch((error) => {
        console.error(error); // Обработка ошибки
      });
  };

  return (
    <section className="Contact">
      <h2>{lang === 'en' ? 'Contact us:' : 'Contactez-nous:'}</h2>
      <form className="Contact__form" onSubmit={handleSubmit}>
        <div className="Contact__form__inputs">
          <div className="Contact__form__inputs__name">
            <div className="Contact__form__inputs__name__first">
              <label>
                {lang === 'en' ? 'First name' : 'Prénom'}
                <input
                  type="text"
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="Contact__form__inputs__name__last">
              <label>
                {lang === 'en' ? 'Last name ' : 'Nom de famille'}
                <input
                  type="text"
                  id="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </label>
            </div>
          </div>
          <label>
            {lang === 'en' ? 'Email ' : 'E-mail'}
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
            />
          </label>
          <label>
            {lang === 'en' ? 'Phone number' : 'Numéro de téléphone'}
            <input
              type="phone"
              id="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </label>
          <label>
            {lang === 'en' ? 'Subject' : 'Sujet'}
            <input
              type="text"
              id="subject"
              value={formData.subject}
              onChange={handleChange}
            />
          </label>
          <label>
            {lang === 'en' ? 'Message' : 'Message'}
            <textarea
              rows="15"
              id="message"
              value={formData.message}
              onChange={handleChange}
            />
          </label>
          <div className="checkboxWrapper">
            <input
              type="checkbox"
              id="consent"
              className="checkbox"
              checked={formData.consent}
              onChange={handleChange}
            />
            <label htmlFor="consent" className="checkboxLabel">
              {lang === 'en'
                ? 'I consent to receive additional communications from YXY Immigration Consultant Inc.'
                : 'Je consens à recevoir des communications supplémentaires de YXY Immigration Consultant Inc.'}
            </label>
          </div>
        </div>
        <button type="submit">{lang === 'en' ? 'Submit' : 'Soumettre'}</button>
      </form>
    </section>
  );
}

export default Contact;
